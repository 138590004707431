import React, { useEffect, useState } from "react";

import Table from "./Table";

import axios from "axios";

const RomaLive = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get('https://bazaar.rumalive.com/appTesting/retriveTestingHistory',{
        headers: {
            key:"O]n@BcBKUi8uAaSTU6WO2Z4h|,M*[o"
        }
      })
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  const mapData = [
    {
      Header: "Index",
      Cell: ({ index }) => <span className="  text-nowrap">{index + 1}</span>,
    },

   
    {
      Header: "Device Name",
      body: "domainName",
      Cell: ({ row, index }) => (
        <span className="  text-nowrap">{row?.deviceName || "-"}</span>
      ),
    },
    {
      Header: "User",
      body: "user",
      Cell: ({ row, index }) => (
        <span className="  text-nowrap">{row?.user || "-"}</span>
      ),
    },
    {
      Header: "Identity",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.identity || "-"}</span>
      ),
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        // Helper function to get last 5 days in MM/DD format
        const getLast5Days = () => {
          const days = [];
          const currentDate = new Date();
    
          for (let i = 0; i < 5; i++) {
            const tempDate = new Date(currentDate);
            tempDate.setDate(currentDate.getDate() - i); // Get today and last 4 days
            const formattedDate = tempDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }); // Format as MM/DD
            days.push(formattedDate);
          }
          return days.reverse(); // Reverse to show in ascending order
        };
    
        // Get the last 5 days
        const last5Days = getLast5Days();
    
        // Function to check if a date exists in the row's date array, comparing only MM/DD
        const isDateChecked = (dateToCheck) => {
          // Loop through the row.date array and compare only the date part (MM/DD)
          return row?.date?.some((fullDate) => {
            const datePart = fullDate.split(",")[0]; // Get only the date part (MM/DD/YYYY)
            const formattedDatePart = new Date(datePart).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }); // Convert to MM/DD
            return formattedDatePart === dateToCheck;
          });
        };
    
        return (
          <div className="scrollable-container ">
            {/* First line: Show the last 5 days (MM/DD) */}
            <div className="date-row" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              {last5Days.map((day, index) => (
                <div key={index} style={{ textAlign: 'center', flex: 1 }}>
                  {day}
                </div>
              ))}
            </div>
    
            {/* Second line: Checkboxes for the last 5 days */}
            <div className="checkbox-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
              {last5Days.map((day, index) => (
                <div key={index} style={{ textAlign: 'center', flex: 1 ,fontSize:'8px'}}>
                  <input
                    type="checkbox"
                    checked={isDateChecked(day)}
                    readOnly
                  />
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    
    {
      Header: "createdAt",
      Cell: ({ row }) => (
        <span className="  text-nowrap">{row?.createdAt.split("T")[0]}</span>
      ),
    },
  ];

  return (
    <div>
        <>
          <div className="plan-table testApp pb-4">

            <Table data={data} mapData={mapData}  />
          </div>
        </>
    </div>
  );
};
export default RomaLive;
