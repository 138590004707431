import logo from "./logo.svg";
import "./App.css";
import Main from "./Main";
import bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import RomaLive from "./RomaLive";
import ATMLive from "./ATMLive";

function App() {
  const defaultApp = localStorage.getItem("app");
  const defaultColor = localStorage.getItem("color");

  const [app, setApp] = useState(defaultApp || "roma");
  const [color, setColor] = useState(defaultColor || "text-primary");
  const handleSetApp = (app,color) => {
    console.log("app",app);
    localStorage.setItem("app", app);
    localStorage.setItem("color", color);
    setApp(app);
    setColor(color);
  };

  return (
    <div className="App px-5 ">
      <div className="" style={{ marginTop: "60px" }}>
        <div className="row">
          <div>
            <button
              className="btn btn-primary p-2 mx-2"
              onClick={() => handleSetApp("roma","text-primary")}
            >
              Roma live
            </button>
            <button
              className="btn btn-danger p-2"
              onClick={() => handleSetApp("ATM","text-danger")}
            >
              ATM
            </button>
          </div>
        </div>
        <div className={`text-center mt-2 fw-bold ${color} fs-2 text-capitalize`}>{app}</div>
            {app === "roma" && <RomaLive />}

            {app === "ATM" && <ATMLive />}
      </div>
      {/* <Main /> */}
    </div>
  );
}

export default App;
