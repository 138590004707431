const Button = (props) => {
    const { newClass, btnColor, btnName, onClick, style, btnIcon, disabled,type ,parentClass} =
      props;
  
    return (
      <>
       <div className={`custom-btn ${parentClass}`}>
       <button
          className={`themeBtn text-center ${newClass} ${btnColor}`}
          onClick={onClick}
          style={style}
          disabled={disabled}
          type={type}
        >
          {btnIcon ?
          <>
            {btnIcon} {btnName&&<span className="">{btnName}</span>}
          </>
          : btnName}
        </button>
       </div>
      </>
    );
  };
  
  export default Button;
  